import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CommonLayout from "../common/CommonLayout";
import Home from "../component/Home";
import PredictionDetail from "../component/PredictionDetail";
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import ComingSoon from "../common/ComingSoon";
import TransactionManagement from "../component/TransactionManagement";
import ContactForm from "../component/ContactForm";
import AboutUs from "../component/AboutUs";
import TermServices from "../component/TermServices";
import PrivacyPolicy from "../component/PrivacyPolicy";
import LeaderBoard from "../component/LeaderBoard";
import RiskFreePrediction from "../component/RiskFreePrediction";
import MintNft from "../component/mintNft";
import ReferralBoard from "../component/ReferralBoard";
import Redem from "../component/Redem";
// import MintNft from "../component/MintNft";

const Routing = () => {

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout>{<Home />}</CommonLayout>}>
        </Route>
        <Route
          path="/:dataProvider"
          element={<CommonLayout>{<Home />}</CommonLayout>}>
        </Route>

        <Route
          path="/mintNft"
          element={<CommonLayout>{<MintNft />}</CommonLayout>}>
        </Route>


        <Route
          path="/:dataProvider/:address"
          element={<CommonLayout>{<PredictionDetail />}</CommonLayout>}>
        </Route>
        <Route
          path="/about-us"
          element={<CommonLayout>{<AboutUs/>}</CommonLayout>}>
        </Route>
        <Route
          path="/how-it-works"
          element={<CommonLayout>{<ComingSoon />}</CommonLayout>}>
        </Route>
        <Route
          path="/contact-form"
          element={<CommonLayout>{<ContactForm />}</CommonLayout>}>
        </Route>
        <Route
          path="/transaction-management"
          element={<CommonLayout>{<TransactionManagement />}</CommonLayout>}>
        </Route>
        <Route
          path="/terms-services"
          element={<CommonLayout>{<TermServices/>}</CommonLayout>}>
        </Route>
        <Route
          path="/privacy-policy"
          element={<CommonLayout>{<PrivacyPolicy/>}</CommonLayout>}>
        </Route>
        <Route
          path="/leaderboard"
          element={<CommonLayout>{<LeaderBoard/>}</CommonLayout>}>
        </Route>
        <Route
          path="/referrals"
          element={<CommonLayout>{<ReferralBoard/>}</CommonLayout>}>
        </Route>
        <Route
          path="/redeem"
          element={<CommonLayout>{<Redem/>}</CommonLayout>}>
        </Route>
        <Route
          path="/risk-free-prediction"
          element={<CommonLayout>{<RiskFreePrediction/>}</CommonLayout>}>
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
