import { PersonaAdSDK } from '@personaxyz/ad-sdk';

const personaConfig = {
    apiKey: 'persona-pub-0xfb484f1fe1a61276d8af32d8d8e3a7b1', // An actual API key is generated once you register an app with us.
    environment: 'production', // use value 'production' when going live
}

const sdk = new PersonaAdSDK(personaConfig)

export const adClient = sdk.getClient()
