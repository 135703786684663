
import { useState, useEffect } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { apiService } from "../service/api.service";
import { formatAddress } from "../common/connectWallet";
import { adClient } from '../common/adConfig'
const ReferralBoard = () => {
    const ReferralBoardOptions = [
        { value: "GroupNFT", label: "Group NFT" },
        { value: "SoloNFT", label: "Solo NFT" },
        { value: "ExtendedSoloNFT", label: "Extended solo NFT" }
    ]

    const [loader, setLoader] = useState(false)
    const [allData, setAllData] = useState()
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)
    const [nftStatus, setNftStatus] = useState("GroupNFT")

    useEffect(() => {
        fetchData(nftStatus, page);
    }, [nftStatus])

    async function changeNft(newStatus) {
        setpage(0);
        setNftStatus(newStatus);
    }

    async function fetchData(newStatusTmp, page) {
        setLoader(true)
        try {
            const response = await apiService.referralLeaderBoard(newStatusTmp, page);
            if (response.status == 200) {
                console.log("response.data.data>>>>>", response.data.data);
                setAllData(response.data.data);
                settotalCount(response.data.data.totalRecords);
            }
            setLoader(false)
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }

    function get_rank_class(rank) {
        if (page > 0) {
            return "common-section";
        }
        const rankMap = {
            1: "first-winner",
            2: "second-winner",
            3: "third-winner"
        };
        return rankMap[rank] || "common-section";
    }

    function get_rank_td(rank) {

        if (page > 0) {
            return <td>{rank}</td>
        }

        if (rank == 1) {
            return <td><img src={require("../assets/images/number-one.png")} alt="img" /></td>
        }
        else if (rank == 2) {
            return <td><img src={require("../assets/images/number-two.png")} alt="img" /></td>
        }
        else if (rank == 3) {
            return <td><img src={require("../assets/images/number-three.png")} alt="img" /></td>
        } else {
            return <td>{rank}</td>
        }

    }
    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",
            containerId: "ads-banner-top"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e",
            containerId: "ads-home-right"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0",
            containerId: "ads-home-left"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    return (
        <>
            <div className="ads-banner-top" id="ads-banner-top">

            </div>
            <div id="ads-home-right">

            </div>
            <div id="ads-home-left">

            </div>
            <section className="transaction-management-area">
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="justify-content-center">
                            <Col md={12} lg={10}>
                                <div className="leaderboard-info-top">
                                    <h6>Points Program Rules:</h6>
                                    <p>Placing a prediction = 3 points   |    Winning a prediction = 6 points </p>
                                    <ul>
                                        <li>Placing a prediction with minimum 100 USDC = 10 points </li>
                                        <li>Winning a prediction with minimum 100 USDC = 20 points </li>
                                        <li> Placing a prediction with minimum 1,000 USDC= 30 points</li>
                                        <li>Winning a prediction with minimum 1,000 USDC = 60 points  </li>
                                    </ul>
                                </div>
                                <div className="transaction-top-heading leader-board-info-bottom">
                                    <div className="top-leaderboard-left">
                                        <img src={require("../assets/images/winners.png")} alt="img" />
                                    </div>
                                    <div className="top-leaderboard-right">
                                        <h2>Winner’s Wall <img src={require("../assets/images/medal.png")} alt="img" /></h2>
                                        <p>Celebrating the Top Referrers on BetFolio</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" justify-content-center">
                            <Col md={12} lg={10}>
                                <div className="audit-request-box leaderboard-inner referral-board">
                                    <div className="board-filters referral-board">
                                        <Form.Group
                                            className="mb-2"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Status:</Form.Label>
                                            <Select options={ReferralBoardOptions} placeholder="Group NFT"
                                                onChange={(e) => changeNft(e.value)}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        background: "#1d0042",
                                                        border: "1px solid rgb(255 255 255 / 50%)",
                                                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                                                        borderRadius: "10px",
                                                        "&:focus": {
                                                            borderColor: "rgb(255 255 255 / 50%)",
                                                        },
                                                        "&:hover": {
                                                            borderColor: "rgb(255 255 255 / 50%)",
                                                        },
                                                    }),
                                                }} />
                                        </Form.Group>
                                    </div>
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Rank</th>
                                                <th className="">Wallet Address</th>
                                                <th className="">Referrals</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {console.log("allData----", allData)}
                                            {allData?.referelLeaderBoard && allData?.referelLeaderBoard?.map((data, index) => {
                                                return (
                                                    <tr className={get_rank_class(index + 1)}>
                                                        {get_rank_td(index + 1)}
                                                        <td>{formatAddress(data?.user_wallet_address)}</td>
                                                        <td>{data?.referrals}</td>
                                                    </tr>
                                                )
                                            })}

                                            {allData?.referelLeaderBoard && allData?.referelLeaderBoard.length == 0 &&
                                                <tr>
                                                    <td className="board-no-data-outer">
                                                        <div className="no-data-area main-no-data table-no-data board-no-data">
                                                            <img src={require("../assets/images/leader-board-no-data.png")} alt="img" />
                                                            <p> No Data Found</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>
                                    </Table>

                                    {totalCount && totalCount > 50 ?
                                        <Pagination
                                            activePage={1}
                                            itemsCountPerPage={50}
                                            totalItemsCount={10}
                                            pageRangeDisplayed={5}
                                            // onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
};
export default ReferralBoard;