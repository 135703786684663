
import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import {
    formatBalanceWithDecimals
  } from "../common/connectWallet";
import { appUrl } from '../config/config';

const isInProgressOld = (startDateTime, endDateTime, predictionTime) => {
    let currentTimeStamp = Math.floor(new Date().getTime() / 1000);
    let startTimeStamp = Math.floor(new Date(startDateTime).getTime() / 1000);
    let endTime = new Date(endDateTime);
    let endTimeMinusPredict = endTime.setMinutes(
      endTime.getMinutes() - predictionTime
    );
    let endTimeMinusPredictStamp = Math.floor(
      new Date(endTimeMinusPredict).getTime() / 1000
    );
    if (currentTimeStamp < endTimeMinusPredictStamp) {
      return true;
    } else {
      return false;
    }
  };

const isInProgress = (startDateTime, endDateTime, predictionTime) => {
    const currentTimeStamp = Math.floor(Date.now() / 1000);
    const endTime = new Date(endDateTime);
    endTime.setMinutes(endTime.getMinutes() - predictionTime);
    const endTimeMinusPredictStamp = Math.floor(endTime.getTime() / 1000);
  
    return currentTimeStamp < endTimeMinusPredictStamp;
  };
  


const PredictionBoxBottom = ({ data  }) => {
let history = useNavigate();
  const handleButtonClick = (dataProvider,address,event) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(appUrl+"/"+dataProvider+"/"+address, '_blank');
    }else{
      history("/"+dataProvider+"/"+address);
    }
    
  };

  const getButtonLabel = () => {
    return data.prediction_category === "QUIZ" ? "Answer" : isInProgress(data.startDateTime, data.endDateTime, data.threshold_time) ? "Predict" : "View";
  };

  return (
    <div className="prdiction-box-bottom">
      <p>
        Total:{" "}
        {data?.prediction_type === "RISK-FREE" ? (
          <span>{data.countsAndAmounts.totalCount} {data.countsAndAmounts.totalCount == 1 ? "bet" : "bets"}</span>
        ) : (
          <span>
            {formatBalanceWithDecimals(data.countsAndAmounts.totalAmount, data?.decimal)} {data?.symbol} ({data.countsAndAmounts.totalCount} {data.countsAndAmounts.totalCount == 1 ? "bet" : "bets"})
          </span>
        )}
      </p>
      <Button type="button" variant="unset" onClick={(event) => handleButtonClick(data?.data_provider,data?.contract_address , event)}>
        {getButtonLabel()}
      </Button>
    </div>
  );
};

export default PredictionBoxBottom;
