import React, { useState } from 'react';
import Web3 from 'web3';
import { erc721Abi } from "../config/erc721Abi";

import{soloCounterAddress,soloNft,soloExtendedNft ,groupNft , multiChoiceNft } from "../config/config"
const RISK_FREE_NFT= '0xfC4C01F07c021e8466a7F08d9077d65C1EE4C3FA';


const MintNft = () => {
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState('');
    const [tokenId, setTokenId] = useState('');
    const [status, setStatus] = useState('');

    
    const connectToWeb3 = async () => {
        if (window.ethereum) {
            try {
                const web3Instance = new Web3(window.ethereum);
                await window.ethereum.enable();
                const accounts = await web3Instance.eth.getAccounts();
                setWeb3(web3Instance);
                setAccount(accounts[0]);
                const contractInstance = new web3Instance.eth.Contract(
                    erc721Abi,
                    RISK_FREE_NFT
                    // '0xfC4C01F07c021e8466a7F08d9077d65C1EE4C3FA' // test Replace with your contract address
                    // '0x565b610BBEf2ECADCfdc175313190f0143F6E5CB'// staging
                );
                setContract(contractInstance);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error('Please install MetaMask or another Web3 provider.');
        }
    };

    const get_contract = async (address) => {

        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3Instance.eth.getAccounts();
        setWeb3(web3Instance);
        setAccount(accounts[0]);


        const contractInstance = new web3Instance.eth.Contract(
            erc721Abi,
            address
            // '0xfC4C01F07c021e8466a7F08d9077d65C1EE4C3FA' // test Replace with your contract address
            // '0x565b610BBEf2ECADCfdc175313190f0143F6E5CB'// staging
        );
        return contractInstance;
    }

    const nftMint = async () => {
        try {
            let con = await get_contract(groupNft);
            const result = await con.methods.mint(account).send({ from: account });
            setStatus(`NFT minted successfully with transaction hash: ${result.transactionHash}`);
        } catch (error) {
            console.error(error);
            setStatus('Error minting NFT');
        }
    };


    const mintSoloNft = async () => {
        try {

            let con = await get_contract(soloNft);

            const result = await con.methods.mint(account).send({ from: account });
            setStatus(`solo NFT minted successfully with transaction hash: ${result.transactionHash}`);
        } catch (error) {
            console.error(error);
            setStatus('Error minting NFT');
        }
    };

    const mintExtendedSoloNft = async () => {
        try {
            let con = await get_contract(soloExtendedNft);
            const result = await con.methods.mint(account).send({ from: account });
            setStatus(`Extended solo NFT minted successfully with transaction hash: ${result.transactionHash}`);
        } catch (error) {
            console.error(error);
            setStatus('Error minting NFT');
        }
    };

        const mintMultiiChoiceNft = async () => {

            try {
                let con = await get_contract(multiChoiceNft);
                const result = await con.methods.mint(account).send({ from: account });
                setStatus(`Extended solo NFT minted successfully with transaction hash: ${result.transactionHash}`);
            } catch (error) {
                console.error(error);
                setStatus('Error minting NFT');
            }


        }
    

    return (
        <section className="common-padding prediction-detail-portion">
            <div>
                <button onClick={connectToWeb3}>Connect to Web3</button>
                {web3 && contract && (
                    <div>
                        {account}
                        <button onClick={nftMint}>Mint NFT</button>
                        <br></br>
                        <br></br>
                        <button onClick={mintSoloNft}  >Mint Solo nft </button>
                        <br></br>
                        <br></br>
                        <button onClick={mintExtendedSoloNft}  >Mint Solo Extended nft </button>

                        <br></br>
                        <br></br>
                        <button onClick={mintMultiiChoiceNft}  >Multi Choice nft </button>




                        <p>Status: {status}</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default MintNft;
