import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { useParams, useNavigate } from "react-router-dom";
import { formatBalanceWithDecimals } from "./connectWallet";
import SoloTypeTooltip from '../component/SoloTypeTooltip';
import PredictionAmount from "./PredictionAmount";
import { appUrl } from "../config/config";



const SearchComponent = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [prevSearchTerm, setPrevSearchTerm] = useState("");
    const [globalPredictionFilter, setGlobalPredictionFilter] = useState();
    const [showDropdown, setShowDropdown] = useState(false);
    const [inputFocus, setInputFocus] = useState(false)
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const handleFocus = () => {
        setInputFocus(true);
      };
    
      const handleBlur = () => {
        setTimeout(() => {
            setInputFocus(false);
          }, 200)
      };



    useEffect(() => {
        setShowDropdown(inputFocus);
    }, [inputFocus]);


    useEffect(() => {
        get_predictionFilter(searchTerm);
    }, [searchTerm]);



    const get_predictionFilter = async (searchTerm) => {
        setLoader(true);
        try {
            const response = await apiService.getPredictionFilter(searchTerm);
            if (response.status === 200) {
                console.log("response.data.data>>>>>", response.data?.data);
                setGlobalPredictionFilter(response?.data?.data);
                setShowDropdown(true);
                // setLoader(false);
            }
        } catch (error) {
            setShowDropdown(false);
            setLoader(false);
            if (error?.response?.status === 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            }
        }
    };

    const handleItemClick = (item, event) => {
        console.log("handleItemClick", item);
        const url = `${item.data_provider}/${item.contract_address}`;
        if (event.ctrlKey || event.metaKey) {
            
          window.open(`${appUrl}/${url}`, '_blank');
        } else {
          navigate(`/${url}`);
        }
      };

    const handleInputClick = () => {
        setShowDropdown(false);
    };

    const trimText = (text, limit) => {
        if (text.length > limit) {
            return `${text.slice(0, limit)}...`;
        }
        return text;
    };

    return (
        <>
            <div className="main-search-outer">
                <div className="main-search-area" ref={inputRef}>
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => { setSearchTerm(e.target.value);  }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        
                    />
                    <i className="fa fa-search" aria-hidden="true"></i>
                </div>
                {showDropdown && globalPredictionFilter && (
                    <div className="search-area-dropdown">
                        {globalPredictionFilter?.results?.length > 0 ? (
                            <ul>
                                {globalPredictionFilter.results?.map((item, index) => {
                                    return (
                                        <div className="seacrh-dropdown-inner"  onClick={(event) => handleItemClick(item, event)} >
                                            <div className="search-dropdown-inner-left">
                                                <img src={require("../assets/images/dummy.png")} />
                                                <div className="dropdown-inner-info">
                                                    <h6>{item?.question?.length >50 ? trimText(item?.question, 50) : item?.question}</h6>
                                                    <p>
                                                    {item?.data_provider}
                                                    {item?.solo_type && <SoloTypeTooltip soloType={item?.solo_type} amount={formatBalanceWithDecimals(item?.amount, item?.decimal)} withoutTooltip={true} />}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="search-dropdown-inner-left">
                                                <span>
                                                    <PredictionAmount prediction={item} />
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                        ) : (
                            <div className="search-no-data">
                                <img src={require("../assets/images/no-data.gif")} />
                                <p>No results found</p>
                            </div>
                        )}



                        {/* no-data */}

                    </div>
                )}
            </div>

        </>
    );
};

export default SearchComponent;
