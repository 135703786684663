import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const NotConnectWalletPopup = (props) => {

// console.log(props)
  

    return (
        <>
        
        <Modal show={props.show} onHide={props.handleClose} centered className="connect-wallet-box">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={require("../assets/images/wallet-not-connected.png")} alt="wallet" style={{width: "100px"}} />
                    {/* <img src={require("../assets/images/wallet-connected.png")} alt="wallet" /> */}
                    <h3>Wallet Not Connected</h3>
                    <p>You are not currently connected to the wallet. Please connect.</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="unset" onClick={props.connectWallet}>
                        Connect Wallet
                    </Button>
                </Modal.Footer>
            </Modal></>
    );
};
export default NotConnectWalletPopup;